<template>
  <div data-testid="AuctionListItem" @click="onClick">
    <div
      class="group hidden w-full gap-1 border-b-4 shadow-sm shadow-transparent transition-shadow hover:bg-white hover:shadow-black/10 @lg:grid @lg:grid-cols-[13%,62%,17%,7%] @lg:border-gray-100"
      :class="{
        'cursor-pointer': stage !== 'IN_PREPARATION'
      }"
    >
      <AuctionListItemCategory />
      <AuctionListItemLocation />
      <AuctionListItemInfo />
      <AuctionListImage />
    </div>
    <AuctionListItemMobile />
  </div>

  <LazyCommonDialogAggregateRedirectionInfo
    ref="aggregateModalRef"
    :link="link"
  />
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { useAuctionLink } from '@autobid/ui/composables/useAuctionLink'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { useOpenLink } from '@autobid/ui/composables/useOpenLink'
import type AggregateDialog from '@autobid/ui/components/common/dialog/AggregateRedirectionInfo.vue'
import { SHOULD_BLOCK_TRANSITION } from '@autobid/ui/constants/AuctionListItem/injectionsKeys'
import { AGGREGATE_BID_ID } from '@autobid/ui/constants/AGGREGATE_BID_ID'
import { useQueryClient } from '@tanstack/vue-query'
import { getUseAuctionTanstackKey } from '@autobid/ui/composables/useAuction'
import AuctionListItemCategory from './AuctionListItemCategory.vue'
import AuctionListItemLocation from './AuctionListItemLocation.vue'
import AuctionListItemInfo from './AuctionListItemInfo.vue'
import AuctionListImage from './AuctionListItemImage.vue'
import AuctionListItemMobile from './AuctionListItemMobile.vue'

const auction = defineProps<DisplayingAuction>()
provide('auction', auction)

const { open } = useOpenLink()
const { locale } = useI18n()

const link = useAuctionLink({
  appId: auction.appId,
  slug: auction.slug,
  stage: auction.stage,
  startDate: auction.startDate
})

const isAggregatebidApp = auction.appId === AGGREGATE_BID_ID

const shouldBlockTransition = computed(
  () => isAggregatebidApp && !aggregateModalRef.value?.shouldNotDisplayed
)

provide('link', link)
provide(SHOULD_BLOCK_TRANSITION, shouldBlockTransition)

const aggregateModalRef = ref<InstanceType<typeof AggregateDialog>>()

const queryClient = useQueryClient()

const onClick = () => {
  if (!link.value) {
    return
  }
  queryClient.setQueryData(
    getUseAuctionTanstackKey(auction.id, locale.value),
    auction
  )

  if (shouldBlockTransition.value) {
    aggregateModalRef.value.opened = true
    return
  }

  open(link.value)
}
</script>
